<template>
  <basic-page fluid title="Kalustonhuolto">
    <v-row class="fill-height">
      <v-col
        cols="12"
        md="10"
        lg="8"
        xl="6"
        offset-md="1"
        offset-lg="2"
        offset-xl="3"
      >
        <v-card class="mt-5">
          <v-card-title
            >Kalustonhuollon tehtävät
            <v-spacer />
          </v-card-title>
          <v-divider />
          <v-card-text>
            <ol>
              <li>Ajoneuvojen siisteyden varmistaminen</li>
              <li>Kaluston viikkotarkastukset ja koekäytöt</li>
              <li>Paineilmahuolto Porissa</li>
              <li>Letkuhuolto Ulvilassa</li>
              <li>Muut mahdolliset kalustonhuollon tehtävät</li>
              <li>
                HUOM. 511 tarkastuksen yhteydessä moottoriruiskun lataukseen
                laittaminen tarkastuksen alussa ja latauksen lopettaminen
                lähdettäessä.
              </li>
            </ol>
          </v-card-text>
          <v-divider />
          <v-card-title
            >Kalustonhuollon ryhmät
            <v-spacer />
          </v-card-title>
          <v-divider />
          <v-card-text class="pt-7">
            <v-row>
              <v-col cols="1" lg="1" v-if="$vuetify.breakpoint.lgAndUp"></v-col>
              <v-col
                cols="4"
                lg="2"
                v-for="(group, i) in groups"
                :key="group.number"
                :class="[
                  filteredWeeksAndGroups[0].group === group.number &&
                    'current-week',
                  'mt-2',
                ]"
              >
                <v-row
                  style="border: 1px solid #ccc"
                  :class="[i % 2 === 0 && 'odd'].join(' ')"
                >
                  <v-col cols="12">
                    <strong class="text-subtitle-1">{{ group.name }}</strong>
                  </v-col>
                </v-row>
                <v-row
                  v-for="person in group.people"
                  :key="person.name"
                  :class="[i % 2 === 0 && 'odd'].join(' ')"
                  style="
                    border-right: 1px solid #ccc;
                    border-left: 1px solid #ccc;
                  "
                >
                  <v-col cols="12" :style="{ borderBottom: '1px solid #ccc' }">
                    <strong>{{ person.name }}</strong>
                    <br />
                    <span class="caption">{{ person.title }}</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="mt-5" />
          <v-card-title
            >Kalustonhuollon aikataulutus
            <v-spacer />
          </v-card-title>
          <v-divider />
          <v-card-text class="pt-7">
            <v-simple-table>
              <thead>
                <tr>
                  <th>Viikko</th>
                  <th>Yksikkö</th>
                  <th>Ryhmä</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="wg in filteredWeeksAndGroups" :key="wg.week">
                  <td :class="[wg.current && 'blue lighten-5']">
                    <strong>{{ wg.week }}</strong>
                    <span class="caption ml-md-2 d-block d-md-inline-block"
                      >({{ wg.startDate }} - {{ wg.endDate }})</span
                    >
                  </td>
                  <td :class="[wg.current && 'blue lighten-5']">
                    {{ wg.engine }}
                  </td>
                  <td :class="[wg.current && 'blue lighten-5']">
                    {{ wg.group }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
          <v-divider />
          <v-card-title>Tarkastuslomake</v-card-title>
          <v-divider />
          <v-card-text>
            <v-row>
              <v-col cols="10" md="11">
                <v-select
                  v-model="selectedWeek"
                  :items="selectableWeeks"
                  outlined
                  label="Valitse viikko"
                  :loading="loadingSave"
                  :disabled="loadingSave"
                  class="mb-5"
                  hide-details
                >
                </v-select>
              </v-col>
              <v-col cols="2" md="1" class="d-flex justify-end align-center">
                <v-btn
                  @click="showHistoryWeeks = !showHistoryWeeks"
                  icon
                  large
                  class="mt-n3"
                >
                  <v-icon>
                    {{ showHistoryWeeks ? "mdi-update" : "mdi-history" }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-select
              v-model="selectedEngine"
              :items="selectedWeekEngines"
              label="Valitse yksikkö"
              :loading="loadingKalustonhuoltoData"
              outlined
              hide-details
              :disabled="
                !selectedWeek || loadingKalustonhuoltoData || loadingSave
              "
            />
          </v-card-text>
          <v-card-text v-if="selectedEngine && selectedWeek">
            <v-row
              v-for="engine in [selectedEngine]"
              :key="engine"
              class="mb-10"
            >
              <v-col cols="12">
                <h3>{{ engine }}</h3>
                <div
                  v-if="showHistoryWeeks && partsForm && partsForm.createdAt"
                >
                  Luotu: {{ $formatDateAndHourMinute(partsForm.createdAt) }} -
                  <span v-if="partsForm.createdBy">{{
                    partsForm.createdBy.user
                  }}</span>
                </div>
                <div
                  v-if="showHistoryWeeks && partsForm && partsForm.updatedAt"
                >
                  Päivitetty:
                  {{ $formatDateAndHourMinute(partsForm.updatedAt) }} -
                  <span v-if="partsForm.updatedBy">{{
                    partsForm.updatedBy.user
                  }}</span>
                </div>
                <v-divider />
              </v-col>

              <v-col cols="12" v-if="partsForm">
                <template v-for="(ep, i) in partsForm.form">
                  <v-row :key="i">
                    <template v-if="ep.type === 'title'">
                      <v-col
                        cols="12"
                        class="align-center justify-space-between mt-5 mb-5 border-bottom"
                      >
                        <span class="text-subtitle-1 font-weight-bold">{{
                          ep.title
                        }}</span>
                      </v-col>
                    </template>
                    <template v-else>
                      <v-col
                        cols="9"
                        :class="[
                          'd-flex align-center justify-space-between py-1',
                          ep.done === 2 && 'green lighten-5 green--text',
                          ep.done === 1 && 'orange lighten-5 orange--text',
                          ep.done === 0 && 'red lighten-5 red--text',
                          'border-bottom',
                        ]"
                      >
                        <span class="text-subtitle-2">
                          {{ ep.title }}
                          <v-icon
                            v-if="ep.done === 2"
                            class="green lighten-5 green--text"
                            >mdi mdi-check</v-icon
                          >
                          <v-icon
                            v-if="ep.done === 1"
                            class="orange lighten-5 orange--text"
                            >mdi mdi-exclamation-thick</v-icon
                          >
                          <v-icon
                            v-if="ep.done === 0"
                            class="red lighten-5 red--text"
                            >mdi mdi-alert</v-icon
                          >
                        </span>

                        <span class="caption" v-if="ep.description">{{
                          ep.description
                        }}</span>
                      </v-col>
                      <v-col
                        cols="3"
                        :class="[
                          'd-flex align-center py-1',
                          ep.done === 2 && 'green lighten-5 green--text',
                          ep.done === 1 && 'orange lighten-5 orange--text',
                          ep.done === 0 && 'red lighten-5 red--text',
                          'border-bottom',
                          'justify-end',
                        ]"
                      >
                        <v-btn
                          icon
                          @click="() => (ep.done = 2)"
                          v-if="!ep.done && ep.done !== 0"
                        >
                          <v-icon>mdi mdi-check</v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          @click="() => (ep.done = 1)"
                          v-if="ep.done === 2"
                        >
                          <v-icon>mdi mdi-check</v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          @click="() => (ep.done = 0)"
                          v-if="ep.done === 1"
                        >
                          <v-icon>mdi mdi-check</v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          @click="() => (ep.done = null)"
                          v-if="ep.done === 0"
                        >
                          <v-icon>mdi mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                    </template>
                  </v-row>
                  <!--<v-divider :key="`divider_${i}`" />-->
                </template>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      outlined
                      label="Huomiot"
                      v-model="partsForm.notices"
                    />
                  </v-col>
                  <v-col cols="12" class="mt-n5">
                    <file-upload
                      ref="fileupload"
                      accepted-file-types="image/*"
                      label="Paina tästä lisätäksesi kuvia"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                        v-for="(image, index) in partsForm.files"
                        :key="`${image.filename}_${index}`"
                      >
                        <v-btn
                          icon
                          color="white"
                          outlined
                          small
                          :disabled="loadingSave"
                          class="image-delete-btn"
                          elevation="2"
                          @click.stop="deleteFile(index, image)"
                          ><v-icon>mdi mdi-close</v-icon></v-btn
                        >
                        <v-img
                          @click="downloadBase64File(image)"
                          lazy
                          :src="image.content"
                          :alt="image.filename"
                          width="100%"
                          height="100%"
                          :data-deleted="image._deleted"
                          :class="[image._deleted && 'deleted-image']"
                        ></v-img>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="mt-6">
                  <v-col cols="12">
                    <v-btn
                      block
                      color="primary"
                      @click="saveForm"
                      :loading="loadingSave"
                      >Tallenna {{ engine }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text v-else>
            <v-divider />
            <v-row class="mt-2">
              <v-col cols="12">
                <v-alert type="info" outlined>
                  Valitse viikko ja yksikkö
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <br class="mb-10" />
        <br class="mb-10" />
        <br class="mb-10" />
        <br class="mb-10" />
        <br class="mb-10" />
      </v-col>
    </v-row>
  </basic-page>
</template>

<script>
import BasicPage from "@/components/BasicPage";
import dayjs from "dayjs";
import api from "@/api";
import RefreshButton from "@/components/RefreshButton.vue";
import FileUpload from "@/components/FileUpload.vue";

export default {
  name: "Kalustonhuolto",
  components: { FileUpload, BasicPage },
  data: () => {
    return {
      loading: false,
      users: [],
      editModal: false,
      user: null,
      kalustonhuoltoData: null,
      loadingKalustonhuoltoData: false,
      loadingSave: false,
      showHistoryWeeks: false,
      simulatedNow: null,
      groups: [
        {
          name: "Ryhmä 1",
          number: 1,
          people: [
            { title: "Ryhmänjohtaja", name: "Heikkilä Kai" },
            { title: "Varajohtaja", name: "Halme Jari" },
            { title: "Jäsen", name: "Haapaniemi Eero" },
            { title: "Jäsen", name: "Heiskala Jani-Petteri" },
            { title: "Jäsen", name: "Leinonen Janne" },
            { title: "Jäsen", name: "Reponen Matti" },
            { title: "Jäsen", name: "Jalava Juuso" },
          ],
        },
        {
          name: "Ryhmä 2",
          number: 2,
          people: [
            { title: "Ryhmänjohtaja", name: "Heiskala Matti" },
            { title: "Varajohtaja", name: "Vehkalahti Niko" },
            { title: "Jäsen", name: "Heiskala Juha-Pekka" },
            { title: "Jäsen", name: "Salonen Tomi" },
            { title: "Jäsen", name: "Pentti Pasi" },
            { title: "Jäsen", name: "Pöyhölä Sanna" },
            { title: "Jäsen", name: "Reponen Simo" },
          ],
        },
        {
          name: "Ryhmä 3",
          number: 3,
          people: [
            { title: "Ryhmänjohtaja", name: "Joensuu Jarno" },
            { title: "Varajohtaja", name: "Rautiainen Joni" },
            { title: "Jäsen", name: "Helin Jarkko" },
            { title: "Jäsen", name: "Koskela Esa" },
            { title: "Jäsen", name: "Uusikorpi Rami" },
            { title: "Jäsen", name: "Kanersalo Pia" },
          ],
        },
        {
          name: "Ryhmä 4",
          number: 4,
          people: [
            { title: "Ryhmänjohtaja", name: "Latva Aimo" },
            { title: "Varajohtaja", name: "Pihlajamäki Pasi" },
            { title: "Jäsen", name: "Raikisto Mari" },
            { title: "Jäsen", name: "Vehkalahti Max" },
            { title: "Jäsen", name: "Lähteenmäki Mika" },
            { title: "Jäsen", name: "Vuorenpää Sami" },
          ],
        },
        {
          name: "Ryhmä 5",
          number: 5,
          people: [
            { title: "Ryhmänjohtaja", name: "Leino Joni" },
            { title: "Varajohtaja", name: "Yli-Anttila Tomi" },
            { title: "Jäsen", name: "Leino Jouni" },
            { title: "Jäsen", name: "Toivanen Tuula" },
            { title: "Jäsen", name: "Hanhikangas Arttu" },
            { title: "Jäsen", name: "Ylinen Jarkko" },
            { title: "Jäsen", name: "Lindgren Ville" },
          ],
        },
      ],
      selectedWeek: null,
      selectedEngine: null,
      partsForm: null,
      checkParts: [
        {
          title: "Asema",
          type: "title",
          engines: ["513"],
        },
        {
          title:
            "Aseman lattian pesu, rengasjäljet pesuaineella + katuharjalla, sen jälkeen lopullinen pesu.",
          engines: ["513"],
        },
        {
          title: "Yksikkö",
          type: "title",
          engines: [
            "511",
            "5112",
            "513",
            "517",
            "Pumppuperävaunu",
            "518",
            "5188",
          ],
        },
        {
          title: "Moottori- ja ohjaustehostinöljy",
          engines: ["511", "5112", "513", "517", "Pumppuperävaunu", "5188"],
        },
        {
          title: "Jäähdytysneste",
          engines: ["511", "5112", "513", "517", "5188"],
        },
        {
          title: "Lasinpesujärjestelmä",
          engines: ["511", "5112", "513", "517"],
        },
        {
          title: "Valot",
          engines: [
            "511",
            "5112",
            "513",
            "517",
            "Pumppuperävaunu",
            "518",
            "5188",
          ],
        },
        {
          title: "Hälytinlaitteet ja radiokalusto",
          engines: ["511", "5112", "513", "517"],
        },
        { title: "Jarru- ja kytkinneste", engines: ["517"] },
        {
          title: "Akkuvesi",
          engines: [
            "511",
            "5112",
            "513",
            "517",
            "Pumppuperävaunu",
            "518",
            "5188",
          ],
        },
        {
          title: "Renkaiden kunto ja rengaspaineet akseleilla",
          engines: [
            { engine: "511", description: "8,0/8,0" },
            { engine: "5112", description: "8,0/8,0" },
            { engine: "513", description: "8,0/8,0/8,0/8,0" },
            { engine: "517", description: "Ohjekirja" },
            { engine: "Pumppuperävaunu", description: "2,2/2,2" },
            { engine: "5188", description: "0,6/0,6" },
          ],
        },
        {
          title: "Polttoainemäärä / AD-Blue",
          engines: [
            { engine: "511", description: "AD-Blue" },
            "5112",
            "513",
            { engine: "517", description: "AD-Blue" },
            "Pumppuperävaunu",
            "518",
            "5188",
          ],
        },
        {
          title: "Palopumpun toiminta ja säiliöveden määrä",
          engines: ["511", "5112", "513"],
        },
        {
          title: "Generaattorin toiminta",
          engines: ["511", "5112"],
        },
        {
          title: "Vinssin toiminta",
          engines: ["511", "5112"],
        },
        {
          title: "Korirakenteet, kolhut, naarmut yms.",
          engines: ["511", "5112", "513", "Pumppuperävaunu"],
        },
        {
          title: "Puhtaus ja siisteys",
          engines: [
            "511",
            "5112",
            "513",
            "517",
            "Pumppuperävaunu",
            "518",
            "5188",
          ],
        },
        {
          title: "Irtokalusto",
          type: "title",
          engines: [
            "511",
            "5112",
            "513",
            "517",
            "Pumppuperävaunu",
            "518",
            "5188",
          ],
        },
        {
          title: "Valaisinkalusto, käsivalaisimet akkujen tyhjennys ja lataus",
          engines: ["511", "5112", "513", "Pumppuperävaunu"],
        },
        {
          title: "Moottoriruisku + akun lataaminen + 1 min koekäyttö kuivana",
          engines: ["511"],
        },
        {
          title: "Letkukalusto, oikea määrä ja koko",
          engines: ["511", "5112", "513", "Pumppuperävaunu", "5188"],
        },
        {
          title: "Uppopumput / nokipalosarja",
          engines: ["511", "5112"],
        },
        {
          title: "Armatuuri- ja suihkuputkikalusto",
          engines: ["511", "5112", "513", "Pumppuperävaunu", "5188"],
        },
        {
          title: "Mittarit",
          engines: ["511"],
        },
        {
          title: "Käsisammuttimet",
          engines: ["511", "5112", "513", "517", "518", "5188"],
        },
        {
          title: "Vaahtokalusto / vedenpehmennin",
          engines: ["511", "5112", "513"],
        },
        {
          title: "Pelastusvälinesarja",
          engines: ["511", "5112"],
        },
        {
          title: "Nostotyynykalusto",
          engines: ["511", "5112"],
        },
        {
          title: "Nosto- ja vetoliinat, sakkelit ja pylpyrä",
          engines: ["511", "5112", "513"],
        },
        {
          title: "Käsityökalut",
          engines: ["511", "5112", "513", "Pumppuperävaunu", "518", "5188"],
        },
        {
          title: "Moottorisahat ja laikkaleikkuri (väh. 2 min koekäyttö)",
          engines: ["511", "5112", "513"],
        },
        {
          title: "Sähkö- ja akkukäyttöinen kalusto / akkujen lataus",
          engines: ["511", "5112"],
        },
        {
          title: "Savutuuletinkalusto",
          engines: ["511", "5112"],
        },
        {
          title: "Moottoriruisku (1 min koekäyttö kuivana)",
          engines: ["5112", "Pumppuperävaunu"],
        },
        {
          title: "Imeytysaineet",
          engines: ["511", "5112"],
        },
        {
          title: "Korkealla työskentelyn kalusto, pelastus+katto",
          engines: ["511", "5112"],
        },
        {
          title: "Kemikaalisuojapukukalusto (kassit ovat sinetöityjä)",
          engines: ["511"],
        },
        {
          title: "Vesipelastuskalusto",
          engines: ["511", "5112"],
        },
        {
          title: "Ensivastekalusto",
          engines: ["511", "5112", "517"],
        },
        {
          title: "Tikaskalusto",
          engines: ["511", "5112"],
        },
        {
          title:
            "Paineilmalaitteet, lämpökamera, tietokone/PEKEn käynnistäminen",
          engines: ["511", "5112"],
        },
        {
          title: "511 putkilukon avaimen päivitys Kanta-Porissa 4kk välein",
          engines: ["511"],
        },
        {
          title: "Hälytysmuonat ja -juomat",
          engines: ["511", "5112", "513"],
        },
        {
          title: "Muu irtokalusto",
          engines: [
            "511",
            "5112",
            "513",
            "517",
            "Pumppuperävaunu",
            "518",
            "5188",
          ],
        },
      ],
    };
  },
  watch: {
    selectableWeeks(val) {
      if (val?.findIndex((w) => w.week === this.selectedWeek) > -1) return;
      this.selectedWeek = null;
    },
    selectedWeek(val) {
      this.selectedEngine = null;
      if (!val) this.kalustonhuoltoData = null;
      else this.getKalustonhuoltoByWeek(val);
    },
    selectedEngine() {
      if (!this.selectedEngine) {
        this.partsForm = null;
        return;
      }
      const enginePartsForm = this.kalustonhuoltoData?.find(
        (k) => k.engine === this.selectedEngine && k.week === this.selectedWeek
      );
      if (enginePartsForm) {
        this.partsForm = { ...enginePartsForm, lastModified: Date.now() };
      } else {
        // eslint-disable-next-line no-undef
        this.partsForm = structuredClone({
          week: this.selectedWeek,
          engine: this.selectedEngine,
          form: this.selectedEngineParts,
          notices: "",
          lastModified: Date.now(),
          groupNumber: this.weeksAndGroups.find(
            (wg) => wg.week === this.selectedWeek
          )?.group,
        });
      }
    },
  },
  computed: {
    weeks() {
      return this.weeksAndGroups.map((wg) => wg.week);
    },
    allWeeksInHistory() {
      const start = dayjs("2023-06-05");
      const end = dayjs(this.simulatedNow || undefined);
      const weeks = end.diff(start, "week") + 1;
      return Array.from({ length: weeks }, (_, i) =>
        start.add(i, "week").format("WW/YYYY")
      );
    },
    selectableWeeks() {
      let now = dayjs(this.simulatedNow || undefined);
      if (now.isBefore(dayjs("2023-06-05"))) {
        now = dayjs("2023-06-05");
      }
      if (this.showHistoryWeeks) {
        return [...this.allWeeksInHistory].reverse();
      }
      return [now.format("WW/YYYY")];
    },
    filteredWeeksAndGroups() {
      const now = dayjs(this.simulatedNow || undefined).format("YYYYWW");
      return this.weeksAndGroups.filter((wg) => wg.weekNumber >= now);
    },
    weeksAndGroups() {
      const engines = ["511", "5112, 518, 5188", "513", "517, Pumppuperävaunu"];
      const startDate = dayjs("2023-06-05"); /*.add(-5, "week")*/ // Ryhmä 1 aloittaa 2023-06-05
      let now = dayjs(this.simulatedNow || undefined);
      if (now.isBefore(dayjs("2023-06-05"))) {
        now = dayjs("2023-06-05");
      }
      let nowWeek = now.format("WW/YYYY");

      //const nowGroup = now.diff(startDate, "week");

      const endDate = now.add(15, "weeks");
      const weeks = endDate.diff(startDate, "week");

      const weeksAndGroups = [];
      for (let i = 0; i <= weeks; i++) {
        const d = startDate.add(i, "week");
        const week = d.format("WW/YYYY");
        weeksAndGroups.push({
          week: week,
          weekNumber: d.format("YYYYWW"),
          startDate: d.startOf("week").format("D.M."),
          endDate: d.endOf("week").format("D.M."),
          group: (i + 1) % 5 || 5,
          engine: engines[i % 4],
          current: nowWeek === week,
        });
      }
      return weeksAndGroups;
    },
    selectedWeekEngines() {
      return this.parseEngines(
        this.weeksAndGroups.find((w) => w.week === this.selectedWeek)
      );
    },
    selectedEngineParts() {
      if (!this.selectedEngine) return [];
      return this.getPartsForEngine(
        this.weeksAndGroups.find((w) => w.week === this.selectedWeek),
        this.selectedEngine
      );
    },
  },
  methods: {
    async getKalustonhuoltoByWeek() {
      this.loadingKalustonhuoltoData = true;
      try {
        await api.kalustonhuoltoByWeek(this.selectedWeek).then((response) => {
          this.kalustonhuoltoData = (response?.data || []).map((d) => {
            d?.files?.forEach((f) => {
              f._deleted = false;
            });
            return d;
          });
        });
      } catch (e) {
        console.error(e);
      }
      this.loadingKalustonhuoltoData = false;
    },
    parseEngines(weekAndGroup) {
      if (weekAndGroup?.engine) {
        return weekAndGroup.engine.split(",").map((e) => e.trim());
      }
      return [];
    },
    parseParts(weekAndGroup) {
      const engines = this.parseEngines(weekAndGroup);
      return this.checkParts.filter((p) => {
        return engines.some((e) => {
          return p.engines.some((pe) =>
            typeof pe === "string" ? pe === e : pe.engine === e
          );
        });
      });
    },
    getPartsForEngine(weekAndGroup, engine) {
      const parts = this.parseParts(weekAndGroup);
      return parts
        .filter((p) => {
          return p.engines.some((pe) => {
            return typeof pe === "string"
              ? pe === engine
              : pe.engine === engine;
          });
        })
        .map((p) => {
          return {
            title: p.title,
            description: p.engines.find(
              (pe) => typeof pe === "object" && pe.engine === engine
            )?.description,
            type: p.type,
            done: false,
          };
        });
    },
    reloadAll() {},
    async saveForm() {
      this.loadingSave = true;
      try {
        const files = await this.$refs.fileupload?.[0]?.getFiles();
        const partsForm = {
          ...this.partsForm,
          files: [
            ...(this.partsForm?.files?.filter((f) => !f._deleted) || []),
            ...(files || []),
          ],
        };
        const d = (await api.kalustonhuoltoUpsert(partsForm))?.data;
        d?.files?.forEach((f) => {
          f._deleted = false;
        });
        this.partsForm = d;
        this.$toast("Tallennettu", {
          type: "success",
          timeout: 3000,
        });
        this.$refs.fileupload?.[0]?.reset();
        await this.getKalustonhuoltoByWeek();
      } catch (e) {
        if (e?.message?.indexOf?.("Data is outdated") > -1) {
          this.$toast(
            "Tallennus epäonnistui, koska joku muu on päivittänyt dataa taustalla. Ole hyvä ja lataa sivu uudelleen!",
            {
              type: "error",
              closeButton: RefreshButton,
              toastClassName: "refresh--toast",
              timeout: 15000,
            }
          );
        } else {
          this.$toast("Tallennus epäonnistui " + e, {
            type: "error",
            timeout: 3000,
          });
        }
      }
      this.loadingSave = false;
    },
    downloadBase64File(image) {
      const linkSource = image.content;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = image.filename;
      downloadLink.click();
    },
    deleteFile(index, file) {
      this.partsForm.files[index]._deleted =
        !this.partsForm.files[index]._deleted;
    },
  },
  mounted() {
    this.reloadAll();
    if (!this.selectedWeek) {
      this.selectedWeek = this.selectableWeeks[0];
    }
  },
};
</script>

<style lang="scss" scoped>
.current-talonhenkilo-row {
  td {
    background: var(--v-blue-base);
  }
}
.odd {
  background: #eeeeee;
}
.current-week {
  //background: #e3f2fd !important;
  & .row > .col {
    background: #e3f2fd !important;
  }
}
.container .border-bottom {
  border-color: #ccc !important;
  border-bottom: 1px solid #ccc !important;
}
.image-delete-btn {
  position: relative;
  left: 90%;
  z-index: 2;
  top: 40px;
  background-color: #242424;
}
.deleted-image {
  border: 3px solid red !important;
}
</style>
